<template>
  <v-form ref="eventForm" @submit.prevent="createEvent" style="margin-bottom: 200px !important;">
    <div>
      <h2>
        Create an Event
      </h2>
      <p>
        Share amazing moments with your friends.
      </p>
    </div>
    <v-card class="pb-2" elevation="12">
        <v-card-text>
        <!-- Challenge Type -->
        <v-row style="background-color:#eeebe9;">
          <v-col cols="6" md="12" class="pb-0 pt-1 pr-1">
            <v-select
              v-model="event.challengeType"
              :items="challengeTypes"
              item-text="name"
              item-value="value"
              label="Select a Challenge"
              required
              :rules="[requiredRule(event.challengeType)]"
              :error-messages="getErrorMessage('challengeType')"
            ></v-select>
          </v-col>

          <v-col cols="6" md="12" class="pb-0 pt-1">
            <v-select
              v-model="event.privacyType"
              :items="privacyTypes"
              item-text="name"
              item-value="value"
              label="Privacy"
              required
              :rules="[requiredRule(event.privacyType)]"
              :error-messages="getErrorMessage('privacyType')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <!-- Event Title -->
          <v-col cols="12" md="12" class="py-0">
            <v-text-field
              v-model="event.title"
              label="Title of Event"
              required
              :rules="[requiredRule(event.title)]"
              :error-messages="getErrorMessage('title')"
            ></v-text-field>
          </v-col>

          <!-- Type of Event -->
          <v-col cols="12" md="12" class="py-0">
            <v-select
              v-model="event.type"
              :items="eventTypes"
              label="Paid Event ?"
              required
              :rules="[requiredRule(event.type)]"
              :error-messages="getErrorMessage('type')"
            ></v-select>
            
            <!-- Ticket Price (for Paid Event) -->
            <v-text-field
              v-if="event.type === 'Paid'"
              v-model="event.ticketPrice"
              label="Event Fee"
              required
              :rules="[requiredRule(event.ticketPrice), numericRule(event.ticketPrice, event.type), minValueRule(event.ticketPrice, 0)]"
              :error-messages="getErrorMessage('ticketPrice')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row style="">
          <!-- Start Date and Time -->
          <v-col cols="6" md="6" class="py-0">
            <v-menu
              ref="startDatePicker"
              v-model="startPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="event.startDate"
                  label="Start Date"
                  readonly
                  clearable
                  show-current
                  v-bind="attrs"
                  v-on="on"
                  required
                  :rules="[requiredRule(event.startDate)]"
                  :error-messages="getErrorMessage('startDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="event.startDate"
                @input="$refs.startDatePicker.isActive = false"
                :min="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="6" class="py-0"> 
            <v-menu
              ref="startTimePicker"
              v-model="startTimePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="event.startTime"
                  label="Start Time"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  required
                  :rules="[requiredRule(event.startTime)]"
                  :error-messages="getErrorMessage('startTime')"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="event.startTime"
                @input="$refs.startTimePicker.isActive = false"
                :min="calculateMinTime()"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row style="">
          <!-- End Date and Time -->
          <v-col cols="6" md="6" class="py-0">
            <v-menu
              ref="endDatePicker"
              v-model="endPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="event.endDate"
                  label="End Date"
                  readonly
                  clearable
                  show-current
                  v-bind="attrs"
                  v-on="on"
                  required
                  :rules="[requiredRule(event.endDate)]"
                  :error-messages="getErrorMessage('endDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="event.endDate"
                @input="$refs.endDatePicker.isActive = false"
                :min="event.startDate"
                :max="calculateMaxEndDate()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="6" class="py-0">
            <v-menu
              ref="endTimePicker"
              v-model="endTimePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="event.endTime"
                  label="End Time"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  required
                  :rules="[requiredRule(event.endTime)]"
                  :error-messages="getErrorMessage('endTime')"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="event.endTime"
                @input="$refs.endTimePicker.isActive = false"
                :min="calculateMinEndTime()"
              ></v-time-picker>
            </v-menu>
          </v-col>

          <!-- Reward Gift -->
          <v-col cols="12" class="py-0">
            <v-text-field
              v-model="event.reward"
              label="Reward Gift Amount"
              required
              :rules="[requiredRule(event.reward), rewardRule(event.reward, event.type, event.ticketPrice), minValueRule(event.reward, 1)]"
              :error-messages="getErrorMessage('reward')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- Submit Button -->
          <v-col cols="12" class="py-1" align="center">
            <v-btn 
              :disabled="!isFormValid() || isSubmitting" 
              type="submit"
              color="primary"
            >
              <template v-if="isSubmitting">Creating Event...</template>
              <template v-else>
                Create Event
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      event: {
        challengeType: '',
        privacyType: '',
        title: '',
        type: '',
        ticketPrice: null,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        reward: null
      },
      privacyTypes: [
        { name: 'For Everyone', value: 'public' },
        { name: 'My Community', value: 'private' }
      ],
      challengeTypes: [
        { name: 'Mind Puzzle', value: 'mindpuzzle' }
      ],
      eventTypes: ['Free', 'Paid'],
      startPicker: false,
      startTimePicker: false,
      endPicker: false,
      endTimePicker: false      
      //numericRule: (value) => /^[0-9]+$/.test(value) || 'Only numbers are allowed',
      //minValueRule: (value, min) => parseFloat(value.replace(/,/g, '')) >= min || `Amount must be at least ${min}`
      //minValueRule: (value, min) => parseFloat(value.replace(/,/g, '')) >= min || `Amount must be at least ${min}`
      //rewardRule: (value, min) => parseFloat(value.replace(/,/g, '')) >= min || `Reward must be at least ${min}`
    }
  },
  created() {
    ////console.log('is form valide ', this.isFormValid())
  },
  methods: {
    requiredRule(value) {
      const trimmedValue = typeof value === 'string' ? value.trim() : value

      return trimmedValue !== '' && trimmedValue !== null && trimmedValue !== undefined
    },
    numericRule(value, eventType) {
      const isNumber = /^[0-9]+$/.test(value)
      const isPaidEvent = eventType === 'Paid'

      if (!isNumber) {
        return 'Only numbers are allowed'
      }

      // Additional check for minimum amount based on event type
      if (isPaidEvent && typeof value === 'string' && parseFloat(value.replace(/,/g, '')) < 100) {
        return 'Minimum event fee for Paid Event is 100'
      }

      return true
    },
    // Add a new method for the reward rule
    rewardRule: (value, eventType, eventFee) => {
      const isNumber = /^[0-9]+$/.test(value)
      const isFreeEvent = eventType === 'Free'
      const isPaidEvent = eventType === 'Paid'

      if (!isNumber) {
        return 'Only numbers are allowed'
      }

      // Additional check for minimum reward based on event type
      if (isFreeEvent && typeof value === 'string' && parseFloat(value.replace(/,/g, '')) < 500) {
        return 'Minimum reward for Free Event is 500'
      }

      // Additional check for minimum reward based on event type and 4 times event fee
      if (isPaidEvent && typeof value === 'string' && parseFloat(value.replace(/,/g, '')) < eventFee * 4) {
        return 'Minimum reward for Paid Event is should be at least 4 times the event fee'
      }

      return true
    },
    minValueRule(value, min) {
      const numericValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value

      if (numericValue >= min) {
        return true
      }

      return `Amount must be at least ${min}`
    },
    isFormValid() {

      // Check the validity of all fields
      const challengeTypeValid = this.requiredRule(this.event.challengeType)
      const privacyTypeValid = this.requiredRule(this.event.privacyType)
      const titleValid = this.requiredRule(this.event.title)
      const typeValid = this.requiredRule(this.event.type)
      const startDateValid = this.requiredRule(this.event.startDate)
      const startTimeValid = this.requiredRule(this.event.startTime)
      const endDateValid = this.requiredRule(this.event.endDate)
      const endTimeValid = this.requiredRule(this.event.endTime)
      // Additional check for event fee (ticketPrice) validation if event type is 'Paid'
      const eventFeeValid =
        this.event.type === 'Paid'
          ? this.numericRule(this.event.ticketPrice, this.event.type) &&
            this.minValueRule(this.event.ticketPrice, 100)
          : true
          //this.numericRule(this.event.ticketPrice, this.event.type) &&
          //this.minValueRule(this.event.ticketPrice, 0) // Adjusted condition for ticketPrice validation

      // Additional check for reward gift amount validation
      const rewardAmountValid = 
        //this.rewardRule(this.event.reward, this.event.type, this.event.ticketPrice) &&
        //this.minValueRule(this.event.reward, 1) // Adjusted condition for reward validation
        this.rewardRule(this.event.reward, this.event.type, this.event.ticketPrice) &&
        (this.event.type === 'Free' || this.minValueRule(this.event.reward, 1))

      // Ensure that the validation error for event.ticketPrice is cleared for Free events
      if (this.event.type === 'Free') {
        this.$refs.eventForm.resetValidation('ticketPrice')
      }
      ////console.log('Form Validity:', challengeTypeValid, privacyTypeValid, titleValid, typeValid, startDateValid, startTimeValid, endDateValid, endTimeValid)

      // Log the validity of each field
      ////console.log('Challenge Type Valid:', challengeTypeValid)
      ////console.log('Privacy Type Valid:', privacyTypeValid)
      ////console.log('Title Valid:', titleValid)
      ////console.log('Type Valid:', typeValid)
      ////console.log('Start Date Valid:', startDateValid)
      ////console.log('Start Time Valid:', startTimeValid)
      ////console.log('End Date Valid:', endDateValid)
      ////console.log('End Time Valid:', endTimeValid)
      ////console.log('Event Fee Valid:', eventFeeValid)
      ////console.log('Reward Amount Valid:', rewardAmountValid)

      // Check if there are no error messages for event fee and reward
      const eventFeeError = this.getErrorMessage('ticketPrice').join(', ')
      const rewardError = this.getErrorMessage('reward').join(', ')
      // Return true only if all fields are valid

      return (
        challengeTypeValid &&
        privacyTypeValid &&
        titleValid &&
        typeValid &&
        startDateValid &&
        startTimeValid &&
        endDateValid &&
        endTimeValid &&
        eventFeeValid &&
        rewardAmountValid &&
        !eventFeeError &&
        !rewardError
      )
    },
    calculateMinTime() {
      const currentDateTime = new Date()
      const currentHour = currentDateTime.getHours()
      const currentMinute = currentDateTime.getMinutes()
    
      return `${currentHour}:${currentMinute}`
    },
    calculateMinEndTime() {
      const currentDateTime = new Date()
      const currentHour = currentDateTime.getHours()
      const currentMinute = currentDateTime.getMinutes()
      const minTime = `${currentHour}:${currentMinute + 30}`
      const startDate = new Date(this.event.startDate)
      const endDate = new Date(this.event.endDate)
  
      return startDate.getTime() === endDate.getTime() ? minTime : '00:00'
    },
    calculateMaxEndDate() {
      const maxEndDate = new Date(this.event.startDate)
      
      maxEndDate.setFullYear(maxEndDate.getFullYear() + 1)
 
      return maxEndDate.toISOString().slice(0, 10)
    },
    async createEvent() {
      // Implement your createEvent logic here
      if (this.$store.getters['auth/isLoggedIn']) {
        this.isSubmitting = true
        try {
          const credentials = {
            challengeType: this.event.challengeType,
            privacyType: this.event.privacyType,
            title: this.event.title,
            type: this.event.type.toLowerCase(),
            ticketPrice: this.event.ticketPrice,
            startDate: this.event.startDate,
            startTime: this.event.startTime,
            endDate: this.event.endDate,
            endTime: this.event.endTime,
            reward: this.event.reward,
            userProfile: this.$store.getters['auth/userInfo']
          }

          ////console.log('our credentials are ', credentials)
          // Dispatch the action defined in your Vuex store module
          const ourdata = await this.$store.dispatch('wallet/submitEvent', credentials)

          ////console.log('our data is here ', ourdata)
          if (ourdata && ourdata.success) {
            //////console.log( our data)
            this.$store.dispatch('snackbar/showSnackbar', {
              message: ourdata.message,
              variant: 'black' // Optional, defaults to 'info'
            })
            this.$router.push('/home')

          } else {
            // Handle error response
            this.$store.dispatch('snackbar/showSnackbar', {
              message: ourdata.message,
              variant: 'black' // Optional, defaults to 'info'
            })
            ////console.log('ERROR ! ', ourdata.error)
            ////console.log('snackbar show')
            this.isSubmitting = false
            
          }
        } catch (error) {
          
          ////console.log(error)
          this.$store.dispatch('snackbar/showSnackbar', {
            message: ourdata.message,
            variant: 'black' // Optional, defaults to 'info'
          })

        } finally {
          this.isSubmitting = false
        }
      } else {
        this.$router.push('/login')
      }
    },
    getErrorMessage(fieldName) {
      const ruleResult = this.requiredRule(this.event[fieldName])

      if (fieldName === 'ticketPrice' && this.event.type === 'Free') {
        // Skip validation for ticketPrice when event type is 'Free'
        this.event.ticketPrice = '0'

        return []
      }

      const errorMessages = ruleResult ? [] : ['This field is required']

      // Additional check for specific field validations
      if (fieldName === 'ticketPrice') {
        if (!this.numericRule(this.event.ticketPrice, this.event.type)) {
          errorMessages.push('Only numbers are allowed')
        }
        if (this.event.type === 'Paid' && typeof this.event.ticketPrice === 'string' && parseFloat(this.event.ticketPrice.replace(/,/g, '')) < 100) {
          errorMessages.push('Minimum event fee for Paid Event is 100')
        }
      } else if (fieldName === 'reward') {
        if (!this.numericRule(this.event.reward, this.event.type)) {
          errorMessages.push('Only numbers are allowed')
        }
        if (this.event.type === 'Free' && typeof this.event.reward === 'string' && parseFloat(this.event.reward.replace(/,/g, '')) < 500) {
          errorMessages.push('Minimum reward for Free Event is 500')
        }
        if (this.event.type === 'Paid' && typeof this.event.reward === 'string' && parseFloat(this.event.reward.replace(/,/g, '')) < this.event.ticketPrice * 4) {
          errorMessages.push('Minimum reward for Paid Event should be at least 4 times the event fee')
        }
      }

      // Log the error messages to the console
      ////console.log(`Error messages for ${fieldName}:`, errorMessages)

      // Return the error messages
      return errorMessages
    }
  }
}
</script>